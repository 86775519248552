import { IntelligenceTask } from 'app/intelligence-task';
import { Task } from '../taskboard-ngrx.model';
import * as TaskActions from './taskboard.actions';

// export interface FeatureState {
//   task: State;
// }

export interface State {
tasks: IntelligenceTask[];
}

const initialState: State = {
  tasks: []


};

export function taskReducer(state = initialState, action: TaskActions.TaskActions) {
  switch (action.type) {
    case TaskActions.ADD_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.payload]
      };
    case TaskActions.UPDATE_TASK:
      const updatedRecipe = {
        ...state.tasks[action.payload.index],
        ...action.payload.newTask
      };

      const updatedRecipes = [...state.tasks];
      updatedRecipes[action.payload.index] = updatedRecipe;

      return {
        ...state,
        tasks: updatedRecipes
      };
    case TaskActions.DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((recipe, index) => {
          return index !== action.payload;
        })
      };
    default:
      return state;
  }
}
